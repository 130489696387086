import { ClientJS } from "clientjs";

export const fingerprint = {
  getReport() {
    let report = this.buildReport();

    return navigator.userAgentData
      .getHighEntropyValues(["architecture", "model", "platformVersion", "fullVersionList"])
      .then((values) => {
        report.userAgentClientHints = values;
        return JSON.stringify(report);
      })
      .catch((err) => {
        report.userAgentClientHints = err;
        return JSON.stringify(report);
      });
  },

  buildReport() {
    let client = new ClientJS();
    let report = {};

    report.fingerprint = client.getFingerprint();
    report.userAgent = client.getUserAgent();
    report.engine = client.getEngine();
    report.engineVersion = client.getEngineVersion();
    report.screen = client.getScreenPrint();
    report.plugins = client.getPlugins();
    report.mimeTypes = client.getMimeTypes();
    report.fonts = client.getFonts();
    report.localStorageEnabled = client.isLocalStorage();
    report.sessionStorageEnabled = client.isSessionStorage();
    report.cookiesEnabled = client.isCookie();
    report.timeZone = client.getTimeZone();
    report.language = client.getLanguage();
    report.systemLanguage = client.getSystemLanguage();
    report.canvasEnabled = client.isCanvas();

    return report;
  },
};
export default fingerprint;
