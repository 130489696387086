<template>
  <div class="help vh-100" style="background: #333">
    <v-container :fill-height="!$vuetify.breakpoint.mobile" class="pt-0">
      <v-row style="'margin: 0 auto; justify-content: center">
        <v-card flat tile :style="!$vuetify.breakpoint.mobile ? 'min-width: 100%' : ''"
          ><app-public-header :showHome="user.userId ? true : false" :showBack="true" />
          <v-divider />
          <v-card-text :class="!$vuetify.breakpoint.mobile ? 'ma-5' : ''">
            <h2 class="mb-2">{{ $t("v_help.t_need_help") }}</h2>
            <!-- <p class="mb-1 mt-5">
              {{ $t("v_help.t_if_question_about_task_see_documentation") }}
            </p>
            <v-btn class="my-2" color="primary" outlined href="https://www.ranes.com/documentation/">
              <v-icon size="medium">mdi-open-in-new</v-icon>
              <span class="ml-1">{{ $t("v_help.t_view_documentation") }}</span>
            </v-btn>
            <p class="mb-1 mt-5">{{ $t("v_help.t_contact_support") }}</p> -->
            <v-btn class="my-2 mt-4" color="primary" href="https://ranes.com/support">
              <v-icon size="medium">mdi-open-in-new</v-icon>
              <span class="ml-1">{{ $t("v_help.t_contact_support") }}</span>
            </v-btn>
            <p class="mb-1 mt-4">
              {{ $t("v_help.t_if_directed_by_support_download_diagnostic_data") }}
            </p>

            <v-btn class="my-4" color="warning" outlined @click="downloadTrace()" style="overflow-wrap: break-word">
              <v-icon size="medium">mdi-progress-wrench</v-icon>
              <span class="ml-1">{{ $t("v_help.t_download_diagnostic_data") }}</span>
            </v-btn>
            <br />
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <v-footer
      class="terms-footer"
      :padless="true"
      dense
      absolute
      style="z-index: 9999; height: 130px; background: #333"
    >
      <v-container class="py-0">
        <v-row>
          <v-col class="ml-2 pb-0">
            <div class="text-caption">
              <a href="/diagnostics" style="text-decoration: none; color: inherit">
                {{ $t("v_help.t_version") }} <span v-if="$platform"> {{ $platform }} </span>{{ appVersion }}
              </a>
              <br />
              <span v-if="appBuild"> {{ $t("v_help.t_build") }} {{ appBuild }} <br /></span>
              {{ $t("v_help.t_api") }} {{ apiVersion }} @ {{ apiEndpoint }}
              <br />
              {{ $t("v_help.t_server_time") }} {{ apiTime }} {{ $t("v_help.t_utc") }}
              <br />
              {{ $t("v_help.t_latency") }} {{ apiResponseTime }}
              <br />
              <span v-if="fcmToken && $store.state.api.apiURL.includes('dev')">
                FCM Token
                <span class="monospace">{{ fcmToken }}</span>
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row style="padding-bottom: 3px">
          <v-col>
            <v-btn x-small text plain href="https://ranes.com/privacy-policy/" class="mr-3">
              <span>{{ $t("v_login.t_privacy") }}</span>
            </v-btn>
            <v-btn x-small text plain href="https://ranes.com/terms-and-conditions/" class="mx-0">
              <span>{{ $t("v_login.t_terms") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { InternalService } from "@services";
import diagnostics from "@/diagnostics";
import AppPublicHeader from "@/components/AppPublicHeader";
import { App } from "@capacitor/app";
export default {
  name: "help",

  metaInfo: {
    title: "Help",
  },
  components: {
    AppPublicHeader,
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("notifications", ["fcmToken"]),
  },
  data() {
    return {
      appVersion: "",
      apiEndpoint: "",
      apiVersion: "",
      apiTime: "",
      apiResponseTime: "",
      appBuild: "",
      appPlatform: "",
    };
  },

  methods: {
    getApiVersion() {
      InternalService.versionGet().then((r) => {
        this.apiVersion = r.data.version;
      });
    },

    getApiTime() {
      const t0 = performance.now();
      InternalService.serverTimeGet().then((r) => {
        const t1 = performance.now();
        const responseTime = Math.round((t1 - t0) * 10) / 10;
        this.apiTime = r.data.utc;
        this.apiResponseTime = `${responseTime.toFixed(1)} milliseconds`;
      });
    },
    getAppInfo() {
      if (this.$platform !== "web") {
        App.getInfo().then((a) => {
          this.appBuild = a.build;
          this.appVersion = a.version;
        });
      } else {
        this.appVersion = store.state.settings.version;
      }
    },
    downloadTrace() {
      diagnostics.exportAsync();
    },
  },

  mounted() {
    this.apiEndpoint = store.state.api.apiURL;
    this.getApiVersion();
    this.getApiTime();
    this.getAppInfo();
  },
};
</script>
