import JSZip from "jszip";
import FileSaver from "file-saver";
import { TRACE_LOG_KEY } from "@/diagnostics/tracer";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@/services/token.service";
import { VUEX_KEY } from "@/store";
import fingerprint from "@/diagnostics/fingerprint";
import tracer from "@/diagnostics/tracer";

export const TRACE_FILENAME = "trace";
export const ACCESS_TOKEN_FILENAME = "access-token";
export const REFRESH_TOKEN_FILENAME = "refresh-token";
export const VUEX_FILENAME = "vuex";
export const FINGERPRINT_FILENAME = "fingerprint";
export const ZIP_PREFIX = "ranes-diagnostics-";

export const diagnostics = {
  async getAsync() {
    let data = {};

    try {
      data.fingerprint = await fingerprint.getReport();
    } catch (err) {
      tracer.error("[DIAG] Failed to build a tracing report", err);
    }

    data.trace = localStorage.getItem(TRACE_LOG_KEY);
    data.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    data.refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    data.vuex = localStorage.getItem(VUEX_KEY);

    return data;
  },

  async exportAsync() {
    var timestamp = Date.now().toString();

    let data = await this.getAsync();

    var zip = new JSZip();

    zip.file(TRACE_FILENAME, data?.trace);
    zip.file(ACCESS_TOKEN_FILENAME, data?.accessToken);
    zip.file(REFRESH_TOKEN_FILENAME, data?.refreshToken);
    zip.file(VUEX_FILENAME, data?.vuex);
    zip.file(FINGERPRINT_FILENAME, data?.fingerprint);

    zip
      .generateAsync({
        type: "blob",
      })
      .then((blob) => {
        FileSaver.saveAs(blob, ZIP_PREFIX + timestamp + ".zip");
      })
      .catch((err) => {
        alert(err);
      });
  },

  importAsync(file) {
    return JSZip.loadAsync(file)
      .then(async (zip) => {
        let data = {};

        data.trace = await zip.files[TRACE_FILENAME].async("text");
        data.accessToken = await zip.files[ACCESS_TOKEN_FILENAME].async("text");
        data.refreshToken = await zip.files[REFRESH_TOKEN_FILENAME].async("text");
        data.vuex = await zip.files[VUEX_FILENAME].async("text");
        data.fingerprint = await zip.files[FINGERPRINT_FILENAME].async("text");

        return data;
      })
      .catch((err) => {
        alert(err);
      });
  },
};
export default diagnostics;
